import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/analytics';
import * as firebaseui from 'firebaseui';

const config = {
    apiKey: "AIzaSyB42ziSjoJUgdQ89kGcz6NoHbOw6j4aVCY",
    authDomain: "icba-64ebf.firebaseapp.com",
    databaseURL: "https://icba-64ebf.firebaseio.com",
    projectId: "icba-64ebf",
    storageBucket: "icba-64ebf.appspot.com",
    messagingSenderId: "20810774827",
    appId: "1:20810774827:web:12cdd60e0dbd09abb16d6e",
    measurementId: "G-CW4CJMXPQ6"
};

class Firebase {
    constructor() {
        if (!firebase.apps.length) { firebase.initializeApp(config); }
        this.Auth = firebase.auth;
        this.auth = firebase.auth();
        this.db = firebase.firestore();
        this.Firestore = firebase.firestore;
        this.ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(this.auth);
        this.functions = firebase.functions();
        this.analytics = firebase.analytics();
    }
}

export default Firebase;