import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import SectionsLayout from '../SectionsLayout';

class Home extends React.Component {

  render() {
    return (
      <div className="homeContainer">
        <h1 className="slogan">CHARITY <span className="separator">·</span> EDUCATION <span className="separator">·</span> EMPOWERMENT</h1>
        <Carousel>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="img/homecarousel/houses.jpg"
              alt="Houses on a hill"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="img/homecarousel/business.jpg"
              alt="Business setting"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="img/homecarousel/teaching.jpg"
              alt="Woman teaching"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="img/homecarousel/business1.jpg"
              alt="Business setting"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="img/homecarousel/libraryfood.jpg"
              alt="Library with people eating"
            />
          </Carousel.Item>
        </Carousel>
        <SectionsLayout sections={this.state.sections} />
      </div>
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      sections: [
        this.whoWeAre
      ]
    }
  }

  whoWeAre = {
    title: Home.WhoWeAreTitle,
    colourScheme: "veryLightScheme",
    items: [
      {
        textSide: "left",
        images: [{
            src: "/img/favela.jpg",
            alt: "Favela"
        }],
        paragraphs: [
          {
            text: Home.WhoWeAreText
          }
        ]
      }
    ]
  }
}

Home.WhoWeAreTitle = "WHO ARE WE?";
Home.WhoWeAreText = `ICBAmérica is an institute of charity that operates in Brazil and South América. It was born in 2015, from a genuine intention of helping people in need, including refugees. Our mission is humanitarian. It is to improve the quality of lives of social groups in poverty living in deprived areas, as well as anyone who has been negatively impacted by social injustice among other circumstances.
As an institute of charity, our vision is to be a reference when it comes to food donations, to improve basic personal hygiene, and to support actions that favor women's entrepreneurship as a way to reduce femicide and promote gender equality and justice, as well as other sectors of human needs.
This is the result of our core values, to practice good actions for the benefit of humanity. We believe that giving hands connects us to others, and as a result it creates stronger communities that help to build a more prosperous, just and happier society for everyone.
Join us in this extremely important mission and let's do good for humanity. Together we can change the lives of millions for the better. Those who would like to become volunteers or to donate as an organization or individual can find links above that will help them do so. Welcome to ICBAmérica!`;

export default Home;