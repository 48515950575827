class Repository {

    partners = {
        title: "PARTNERS",
        colourScheme: "veryLightScheme",
        items: [
          {
            textSide: "left",
            images: [{
                src: "/img/partners.png",
                alt: "Logos of our partners"
            }],
            paragraphs: [
              {
                text: `ICBAmérica was founded with the aim to join forces of good, seeking to help those in need. With a unifying force between peoples of different nations, we seek to strengthen human capabilities within the community through collaborative actions.`
              },
              {
                title: `DONATIONS`,
                text: `Join our movement and donate by using the following categories:`,
                links: [
                    {
                        url: "/zacat-sadaqah",
                        text: "Zacat/Sadaqah"
                    },
                    {
                        url: "/donations",
                        text: "General Donations"
                    }
                ]
              }
            ]
          }
        ]
    }

    sections = [
        this.partners
    ]
}

export default Repository;