import React from 'react';

class Repository {

    contact = {
        title: "CONTACT",
        colourScheme: "veryLightScheme",
        items: [
          {
            textSide: "left",
            paragraphs: [
              {
                title: `OUR ADDRESS (USA)`,
                text: `PO BOX 222
                Alameda CA 94501
                USA`
              },
              {
                title: `OUR ADDRESS (BRA)`,
                text: `Quadra SCLRN 715
                Asa Norte
                Brasília - DF - Brazil
                Cep. 70.770-516`
              },
              {
                title: `CONTACT US ON WHATSAPP`,
                custom: (
                  <a href="https://wa.me/556138775823" class="fa fa-whatsapp" target="_blank" rel="noopener noreferrer"><span className="invisibleLinkText">Whatsapp Link</span></a>
                )
              },
              {
                title: `FOLLOW US ON FACEBOOK`,
                custom: (
                  <a href="https://www.facebook.com/pg/icbamerica/" class="fa fa-facebook" target="_blank" rel="noopener noreferrer"><span className="invisibleLinkText">Facebook Link</span></a>
                )
              },
              {
                title: `EMAIL`,
                custom: (
                  <a href="mailto:contato@icbamerica.org">contato@icbamerica.org</a>
                )
              }
            ]
          }
        ]
    }

    sections = [
        this.contact
    ]
}

export default Repository;