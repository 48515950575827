import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function imgCol(images) {
    return (
        images ?
            <Col>
                <span className="verticalCenterSpacer"></span>
                <img src={images[0].src} alt={images[0].alt} width="100%" className="verticalCenter"></img>
            </Col>
        :
            null
    )
}

class SectionsLayout extends React.Component {
    render() {
        return(
            <div>
                {this.props.sections.map((section, index) =>
                    <Container className={section.colourScheme+" contentContainer"} key={"section"+index}>
                        {section.title ? <h2 align="center">{section.title}</h2> : null}
                        {section.items.map((item, index) =>
                            <article className="item" key={"item"+index}>
                                <Row>
                                    <Col xs={12}>
                                        {item.title ? <h3>{item.title}</h3> : null}
                                    </Col>
                                    { item.textSide === "right" ? imgCol(item.images) : null }
                                    <Col sm={12} md={6}>
                                        {item.paragraphs ?
                                            <div>
                                                {item.paragraphs.map((paragraph, index) =>
                                                    <span key={"paragraph"+index}>
                                                        {paragraph.title ? <h4>{paragraph.title}</h4> : null}
                                                        { paragraph.text ?
                                                            paragraph.text.split('\n').map((text, index) => {
                                                                return <p key={"text"+index}>{text}</p>
                                                            })
                                                        :
                                                            null     
                                                        }
                                                        { paragraph.links ?
                                                            paragraph.links.map((link, index) =>
                                                                <div><a href={link.url} key={"link"+index}>{link.text}</a></div>
                                                            )
                                                        :
                                                            null
                                                        }
                                                        { paragraph.custom ?
                                                            paragraph.custom
                                                        :
                                                            null
                                                        }
                                                    </span>
                                                )}
                                            </div>
                                        :
                                            null
                                        }
                                    </Col>
                                    { item.textSide === "left" ? imgCol(item.images) : null }
                                </Row>
                            </article>
                        )}
                    </Container>
                )}
            </div>
        )
    }
}

export default SectionsLayout;