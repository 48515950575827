import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import DonationForm from '../DonationForm';
import Home from '../Home';
import ControlledCarousel from '../ControlledCarousel';
import CountUp from 'react-countup';
import Firebase from '../Firebase';

const firebase = new Firebase();
const db = firebase.db

/*
var FundraiserTemplate = {
    id: "covid19",
    title: "",
    introduction: "",
    numberOfSlideImages: 0,
    video: false,
    description: "",
    descriptionImage: false,
    donatedText: "",
    donatedAmount: 0,
    donatedUnitAmount: 0
}*/

/*
var fundraiser = {
    id: "covid19",
    title: "COVID-19 FUNDRAISER",
    introduction: `URGENT APPEAL: WE ARE LOSING THE BATTLE AGAINST COVID-19.
    \nHELP US DONATE 10 THOUSAND COVID-19 KITS TO 10 THOUSAND PEOPLE PER WEEK.
    \nALLAH SAID IN THE HOLY QUR'AN:
    \n"IF YOU SAVE ONE LIFE IT IS AS IF YOU SAVED ALL OF HUMANITY..."`,
    numberOfCarouselImages: 19,
    video: true,
    description: `We are horrified to witness the devastating impact COVID-19 is having within the families living in Brazil’s poorest neighborhoods (favelas/slums). According to the World Health Organization, global unity and solidarity is a key factor for us to be able to fight against the virus.
    \nThere are around 50 million people in need who live in poverty in Brazil. These families are living in precarious conditions in this country, conditions that are only getting worse due to the increased lack of income and support. Millions of adults and children are suffering and losing their loved ones everyday, as they do not have access to masks, hygiene products and the difficulty to self isolate in their cramped communities. These families require urgent help to be able to survive the pandemic. Every donation will make a massive impact in their life.
    \nBelow is how the donation per masks and kits works:`,
    descriptionImage: true,
    donatedText: "UNITS DONATED:",
    donatedAmount: 100,
    donatedUnitAmount: 15
}*/

class Fundraiser extends React.Component {

  render() {
    return (
      <div className="fundraiserContainer">
        <Container>
          <Row>
            <Col>
              <h1 className="text-center">{this.state.fundraiser.title}</h1>
            </Col>
          </Row>
        </Container>

        <Container className="introduction">
          <Row>
            { this.state.fundraiser.introduction ?
              <Col>
                {this.state.fundraiser.introduction.split('\\n').map((text, index) => {
                    return <p key={"introduction"+index}>{text}</p>
                })}
              </Col>
            :
                null     
            }
          </Row>
        </Container>
  
        { this.state.fundraiser.video ?
          <Container>
            <Row>
              <Col md={{offset: 1, span: 10}}>
                <video width="100%" height="auto" controls>
                  <source src={this.videoUrl()} type="video/mp4"></source>
                  Your browser does not support the video tag.
                </video>
              </Col>
            </Row>
          </Container>
        :
            null
        }

        { this.state.fundraiser.donatedText ? 
          <Container className="donated">
            <Row>
              <Col md={{offset: 2, span: 8}} className="text-center">
                <h4>{this.state.fundraiser.donatedText}</h4>
                <h1><CountUp end={this.donatedUnits()} duration={3} separator="," ></CountUp></h1>
              </Col>
            </Row>
          </Container>
        :
          null
        }
      
        <Container className="carousel" ref={(el) => this.carouselElement = el}>
          <Row>
            { this.state.fundraiser.numberOfCarouselImages > 0 ?
              <Col lg={{offset: 2, span: 8}}>
                <ControlledCarousel animating={this.state.animating} items={this.carouselItems()} />
              </Col>
            :
                null     
            }
          </Row>
        </Container>

        { this.state.fundraiser.description ?
          <Container className="description lightScheme">
            <Row>
              <Col>   
                <h4 className="text-center">WE NEED YOUR HELP</h4>             
                <div className="text">
                  { this.state.fundraiser.description.split('\\n').map((text, index) => {
                      return <p key={"description"+index}>{text}</p>
                    })
                  }
                </div>
              </Col>
            </Row>
          </Container>
        :
            null
        }

        { this.state.fundraiser.descriptionImage ?
            <Container className="descriptionImage">
              <Row>
                <Col md={{offset: 3, span: 6}}>
                  <img src={this.descriptionImageUrl()} alt="Description explainer"></img>
                </Col>
              </Row>
            </Container>
        :
            null
        }

        <Container className="whoWeAre">
          <Row>
            <Col>
              <h4 className="text-center">{this.state.whoWeAre.title}</h4>
              <div className="text">
                {this.state.whoWeAre.text.split('\n').map((text, index) => {
                    return <p key={"whoWeAre"+index}>{text}</p>
                })}
              </div>
            </Col>
          </Row>
        </Container>

        <Container className="donation lightScheme">
          <Row>
            <Col md={{offset: 3, span: 6}}>
              <h4>"IF YOU SAVE ONE LIFE IT IS AS IF YOU SAVED ALL OF HUMANITY..."</h4>
              <DonationForm.InjectedCheckoutFormContainer paymentDescription={this.state.fundraiser.id} metadata={{fundraiser: true}} paymentComplete={this.reloadAfterPayment}/>
            </Col>
          </Row>
        </Container>

        <Container className="actions text-center">
          <Row>
            <Col>
                <h4>FURTHER ACTIONS</h4>
            </Col>
          </Row>
         <Row>
            <Col>
                <p className="text-center"><i>Please share <b>{this.shareUrl()}</b> with your friends and family</i><br /><span className="clipboard" onClick={() => {navigator.clipboard.writeText(this.shareUrl())}}>Click here to copy <span role="img" aria-label="Copy emoji">📋</span></span></p>
            </Col>
          </Row>
          <Row>
            <Col>
                <a href="https://icbamerica.org">Find out about ICBAmérica</a>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  constructor(props) {
    super(props);
    this.state = {
        fundraiser: {},
        whoWeAre: {
          title: Home.WhoWeAreTitle,
          text: Home.WhoWeAreText
        },
        donatedUnits: 0,
        animating: false
    }
    this.imageUrls = this.imageUrls.bind(this);
    this.videoUrl = this.videoUrl.bind(this);
    this.carouselItems = this.carouselItems.bind(this);
    this.descriptionImageUrl = this.descriptionImageUrl.bind(this);
    this.reloadAfterPayment = this.reloadAfterPayment.bind(this);
    this.shareUrl = this.shareUrl.bind(this);
  }

  componentDidMount() {
    this.fetchFundraiser();
    window.addEventListener('scroll', this.startStopCarousel)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.startStopCarousel)
  }

  fetchFundraiser() {
    const { params } = this.props.match;
    const fundraiserId = params.id;
    var self = this;
    db.collection('fundraisers').doc(fundraiserId ?? "covid19kit")
    .get()
    .then(function(doc) {
        if (doc.exists) {
            const fundraiser = doc.data();
            fundraiser.id = doc.id;
            self.setState({ fundraiser: fundraiser });
        }
    }).catch(function(error) {
        alert(error.message);
    });
  }

  reloadAfterPayment() {
    var self = this;
    setTimeout(function() { self.fetchFundraiser() }, 1500);
  }

  carouselItems() {
    return this.imageUrls().map((imageUrl, index) => {
      return <Carousel.Item key={"carouselItem"+index}>
          <img
              className="d-block w-100 carouselImg"
              src={imageUrl}
              alt="Fundraiser"
          />
      </Carousel.Item>
      })
  }

  imageUrls() {
      var imageUrls = [];
      for (let i = 1; i <= this.state.fundraiser.numberOfCarouselImages; i++) {
        imageUrls.push(this.mediaUrl()+i+".jpeg?alt=media");
      }
      return imageUrls;
  }

  videoUrl() {
    return this.mediaUrl()+"video.mp4?alt=media"
  }

  descriptionImageUrl() {
    return this.mediaUrl()+"description.png?alt=media"
  }

  mediaUrl() {
    return "https://firebasestorage.googleapis.com/v0/b/icba-64ebf.appspot.com/o/fundraiser%2F"+this.state.fundraiser.id+"%2F"
  }

  donatedUnits() {
    return parseInt((this.state.fundraiser.donatedAmount/100)/this.state.fundraiser.donatedUnitAmount)
  }

  startStopCarousel = () => {
    const animating = this.isInViewport(this.carouselElement) ? true : false
    if(this.state.animating !== animating) {
      this.setState({ animating: animating })
    }
  }

  isInViewport(element) {
      const offset = 0;
      if (!element) return false;
      const top = element.getBoundingClientRect().top;
      return (top + offset) >= 0 && (top - offset) <= window.innerHeight;
  }

  shareUrl() {
    const path = window.location.pathname
    const lastCharacterIndex = path.length - 1
    if(path[lastCharacterIndex] !== "/") return window.location.hostname + path;
    return window.location.hostname + path.substring(0, lastCharacterIndex);
  }
}

export default Fundraiser;