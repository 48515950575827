import React from 'react';
import DonationForm from '../components/DonationForm';


class Repository {

    paymentDescription = "";
    constructor(paymentDescription) {
      this.paymentDescription = paymentDescription;
    }
  
    donations() {
      return {
        title: "THE IMPACT OF YOUR DONATION",
        text: `
        Here is how you can help: You can commit to sponsor one time or monthly for Basic Food Kits or Hygiene Kits for as many families as you want. Below are some examples to inspire you:
        US$10.00 to US$100.00 - I would like to donate towards Basic Food Kits.
        US$300.00 - I would like to donate 1 Basic Food Kit annually for 1 family of 2.
        US$600.00 - I would like to donate 2 Basic Food Kits annually for 1 family of 4.
        US$900.00 - I would like to donate 3 Basic Food Kits annually for 1 family of 6.
        From US$5.00 to US$50.00 - I would like to donate one time only towards a personal Hygiene Kit.
        `,
        custom: (
          <DonationForm.InjectedCheckoutFormContainer paymentDescription={this.paymentDescription}/>
        )
      }
    };

    partners() {
        return {
          title: "DONATIONS",
          colourScheme: "veryLightScheme",
          items: [
            {
              textSide: "left",
              images: [{
                  src: "/img/donations.jpg",
                  alt: "People touching arms in a circle"
              }],
              paragraphs: [
                {
                  text: `At ICBAmérica, we have several social services programs/projects aimed at low income communities, such as distribution of food through the Basic Food Kit project, Hygiene Kit project and support for Women's Entrepreneurship, gender justice and women's rights.
                  Considering the above, you can voluntarily contribute to the development and execution of these important social projects.`
                },
                this.donations()
              ]
            }
          ]
      }
    }

    sections() {
        return [this.partners()]
    }
}

export default Repository;