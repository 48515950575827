import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class Gallery extends React.Component {

  render() {
    return (
      <div className="galleryContainer">
        <h1 className="slogan">HERE ARE SOME PICTURES OF OUR ACTIVITIES</h1>
        <Row>
        {this.state.images.map((image) =>
          <Col lg={6} key={"img"+image.name}>
            <div className="image">
              <img src={"/img/gallery/"+image.name} alt={image.alt}></img>
            </div>
          </Col>
        )}
        </Row>
      </div>
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      images: [
        {
          name: "1.JPG",
          alt: "Boy with food"
        },
        {
          name: "2.JPG",
          alt: "People queueing"
        },
        {
          name: "3.JPG",
          alt: "Man with food"
        },
        {
          name: "4.JPG",
          alt: "Women waiting"
        },
        {
          name: "5.JPG",
          alt: "Mum with child and food"
        },
        {
          name: "6.JPG",
          alt: "People queueing"
        },
        {
          name: "7.JPG",
          alt: "People queuing for food"
        },
        {
          name: "8.JPG",
          alt: "Food kit"
        },
        {
          name: "9.JPG",
          alt: "Man with food kit"
        }/*,
        {
          name: "10.JPG",
          alt: "Man waiting for food"
        }*/
      ]
    }
  }
}

export default Gallery;
