import DonationsRepository from './donationsRepository.js';
const donationsRepository = new DonationsRepository("zacat-sadaqah");

class Repository {

    partners() {
      return {
        title: "ZACAT/SADAQAH",
        colourScheme: "veryLightScheme",
        items: [
          {
            textSide: "left",
            images: [{
                src: "/img/peoplelearning.jpg",
                alt: "People learning"
            }],
            paragraphs: [
              {
                text: `Assalamu Alaykum,
                In the name of Allah, The Merciful, The Just, The Knowledgeable of everything that goes on in our hearts, contribute with your ZACAT and SADAQAH, and help the brothers and sisters in need in Brazil and South America.
                We have implemented several social services aimed at the community of low social condition, such as distribution of food through the Basic Food Kit scheme, Hygiene Kit and support for Women's Entrepreneurship, gender justice and women's rights.
                Considering the above, you will be able to pay your annual ZACAT through our organization and we will distribute according to the commandments of the Holy Quran.
                You will also be able to voluntarily donate your SADAQAH, through our organization and we will use it in the various DAWAH programs as well as other projects that help the broader community in need, also in accordance with commandments of the Holy Quran.
                `
              },
              donationsRepository.donations()
            ]
          }
        ]
      }
    }

    sections() {
      return [this.partners()]
    }
}

export default Repository;