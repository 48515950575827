import React, { Component } from 'react';
import {CardElement} from '@stripe/react-stripe-js';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Firebase from '../Firebase';
import {Elements, ElementsConsumer} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
const firebase = new Firebase();
const dev = false
const stripePromise = loadStripe(dev ? 'pk_test_51GvUQlGYNhLnlOq10kkFFfQHoa9UT5dHVBoGXE40J7SkIyzh5XAoXqybmjahXTTkcxkF1CkiOwqNcqCZu2sheyQb00zFLfXY3k' : 'pk_live_51GvUQlGYNhLnlOq1c7pqtCrftQegYRgy1AZgDimw1TUch1nKlOHKXT9OAZsLwd776utMwvJVT6E891SI20R9LY6P002Q45rlYU');

class DonationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderDetails: {
                email: "",
                amount: ""
            },
            cardError: null,
            cardComplete: false,
            isLoading: false,
            errorMessage: null,
            paymentIntentId: null
        }
        this.submit = this.submit.bind(this);
        this.isFormComplete = this.isFormComplete.bind(this);
        this.onChange = this.onChange.bind(this);
        this.donateAgain = this.donateAgain.bind(this);
    }

    render() {
        return (
            <React.Fragment>
                { this.state.paymentIntentId ? this.completeMessage() : this.checkoutForm() }
            </React.Fragment>
        )
    }

    checkoutForm() {
        return (
            <div className="donationForm">
                <Form>
                    <p><b>Please enter your donation amount in USD$ and email address</b></p>
                    <Form.Row>
                        <Col>
                            <Form.Group>
                                <Form.Control className="form-control" type="number" value={this.state.orderDetails.amount} onChange={this.updateOrderDetails.bind(this, "amount")} />
                                <label className="currency-label">$</label>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Control placeholder="Email address" value={this.state.orderDetails.email} onChange={this.updateOrderDetails.bind(this, "email")} />
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col>
                            <Form.Group>
                                <CardElement className={"form-control"} options={{
                                    hidePostalCode: true,
                                    style: {
                                        base: {
                                            fontSize: '16px',
                                            color: '#424770',
                                            '::placeholder': {
                                                color: '#aab7c4',
                                            },
                                        },
                                        invalid: {
                                            color: '#9e2146',
                                        },
                                    },
                                }} onChange={this.onChange}/>
                            </Form.Group>
                        </Col>
                    </Form.Row>
                </Form>
                
                <Row>
                    <Col>
                        <Button variant="success" size="lg" block onClick={this.submit} disabled={!this.isFormComplete() || this.state.isLoading}>{this.state.isLoading ? "Processing..." : "Donate"}</Button>
                    </Col>
                </Row>
                {this.state.errorMessage ?
                    <Row>
                        <Col>
                            <Alert variant="danger">{this.state.errorMessage}</Alert>
                        </Col>
                    </Row>
                :
                    null
                }
                <Row>
                    <Col><p className="privacyText text-center"><i>Your email will be stored securely and not shared with any third parties.</i></p></Col>
                </Row>
            </div>
        );
    }

    completeMessage() {
        return (
            <Row>
                <Col>
                    <h4>THANK YOU FOR YOUR DONATION</h4>
                    <p>You will receive a receipt by email.</p>
                    <p>Your donation id: <i>{this.state.paymentIntentId}</i></p>
                    <Button variant="success" size="lg" onClick={this.donateAgain}>Donate Again</Button>
                </Col>
                <Col sm={2}>
                    <img src="/img/tick.png" width="100%" height="auto" alt="tick"></img>
                </Col>
            </Row>
        )
    }

    updateOrderDetails(key, event) {
        var orderDetails = this.state.orderDetails;
        orderDetails[key] = event.target.value;
        this.setState({orderDetails: orderDetails});
    }

    submit() {
        const {elements} = this.props;
        const cardElement = elements.getElement(CardElement);
        this.setState({isLoading: true});
        var self = this;
        var createPaymentIntent = firebase.functions.httpsCallable("createPaymentIntent");
        createPaymentIntent({amount: this.state.orderDetails.amount*100, email: this.state.orderDetails.email, description: this.props.paymentDescription, metadata: this.props.metadata, dev: dev})
        .then(function(result) {
            return self.props.stripe.confirmCardPayment(
                result.data.client_secret, {
                    payment_method: {
                        card: cardElement
                    }
                }
            )
        }).then(function(result) {
            const state = result.error ? {isLoading: false, errorMessage: result.error.message } : { isLoading: false, paymentIntentId: result.paymentIntent.id }
            self.setState(state);
            if(self.props.paymentComplete) self.props.paymentComplete()
        }).catch(function (error) {
            self.setState({isLoading: false, errorMessage: error.message});
        });
    }

    onChange(event) {
        this.setState({cardError: event.error ? event.error.message : null, cardComplete: event.complete})
    };

    isFormComplete() {
        for(var key in this.state.orderDetails) {
            if(this.state.orderDetails[key] === "") return false;
        }
        return this.state.cardError === null && this.state.cardComplete;
    }

    donateAgain() {
        this.setState({ paymentIntentId: null })
    }
}

DonationForm.InjectedCheckoutForm = (props) => {
    return (
        <ElementsConsumer>
            {({elements, stripe}) => (
                <DonationForm elements={elements} stripe={stripe} {...props} />
            )}
        </ElementsConsumer>
    );
}

DonationForm.InjectedCheckoutFormContainer = (props) => {
    return (
        <Elements stripe={stripePromise}>
            <DonationForm.InjectedCheckoutForm {...props} />
        </Elements>
    )
}

export default DonationForm;
