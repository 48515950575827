import React from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';
// import { Redirect } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';

import Home from '../Home';
import Gallery from '../Gallery';
import Volunteering from '../Volunteering';
import SectionsLayout from '../SectionsLayout';
import Fundraiser from '../Fundraiser';

import ProgramsRepository from '../../repositories/programsRepository.js';
import PartnersRepository from '../../repositories/partnersRepository.js';
import ZacatSadaqahRepository from '../../repositories/zacatSadaqahRepository.js';
import DonationsRepository from '../../repositories/donationsRepository.js';
import ContactRepository from '../../repositories/contactRepository.js';

// import Auth from '../Auth';
import Firebase from '../Firebase';
import CookieProvider from '../CookieProvider';
const firebase = new Firebase();
const db = firebase.db

// const authHelper = {
//     isLoggedIn() { return CookieProvider.get('uid') !== 'null'; }
// }

function LoggedInOutNavElements(props) {
    if(!props.isLoggedIn) {
        return (
            <React.Fragment>
                {/* <Nav.Link href="/login">Login</Nav.Link> */}
                <Nav.Link href="/programs">Programs</Nav.Link>
                <Nav.Link href="/gallery">Gallery</Nav.Link>
                <Nav.Link href="/partners">Partners</Nav.Link>
                <Nav.Link href="/zacat-sadaqah">Zacat/Sadaqah</Nav.Link>
                <Nav.Link href="/donations">Donations</Nav.Link>
                <Nav.Link href="/volunteering">Volunteering</Nav.Link>
                <Nav.Link href="/contact">Contact</Nav.Link>
            </React.Fragment>
        )
    }
    return (
        <React.Fragment>
            <Nav.Link href="/" onClick={() => {
                firebase.auth.signOut()
                CookieProvider.set('uid', null)
                CookieProvider.set('email', null) }}>
                Logout
            </Nav.Link>
        </React.Fragment>
    )
}

// function PrivateRoute({ component: Component, ...rest }) {
//     return (
//         <Route
//             {...rest}
//             render={props =>
//                 authHelper.isLoggedIn() ? (
//                     <Component {...props} />
//                 ) : (
//                         <Redirect
//                             to={{
//                                 pathname: "/login",
//                                 state: { from: props.location }
//                             }}
//                         />
//                     )
//             }
//         />
//     );
// }

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasDeterminedAuthStatus: false, isLoggedIn: false };
    }

    render() {
        var isLoggedIn = this.state.isLoggedIn;
        var isFundraiser = window.location.href.includes("fundraiser");
        var fundraiserContainer = (
            <Container className="mainContainer">
                <Route exact path={["/", "/:id"]} component={Fundraiser} />
            </Container>
        )
        var normalContainer = (
            <Container className="mainContainer">
                <Route exact path="/" component={Home} />
                <Route exact path="/programs" render={(props) => <SectionsLayout {...props} sections={new ProgramsRepository().sections} />} />
                <Route exact path="/gallery" component={Gallery} />
                <Route exact path="/partners" render={(props) => <SectionsLayout {...props} sections={new PartnersRepository().sections} />} />
                <Route exact path="/zacat-sadaqah" render={(props) => <SectionsLayout {...props} sections={new ZacatSadaqahRepository().sections()} />} />
                <Route exact path="/donations" render={(props) => <SectionsLayout {...props} sections={new DonationsRepository("donations").sections()} />} />
                <Route exact path="/volunteering" component={Volunteering} />
                <Route exact path="/contact" render={(props) => <SectionsLayout {...props} sections={new ContactRepository().sections} />} />
                {/* <PrivateRoute exact path="/protected" component={Auth} /> */}
                {/* <Route exact path="/login" component={Auth} /> */}
            </Container>
        )
        var container = isFundraiser ? fundraiserContainer : normalContainer;
        return (
            <Router>
                <Navbar bg="light" expand="lg">
                    <Container>
                    <Navbar.Brand href="/"><img src={isFundraiser ? "/img/Logo_fundraiser_transparent.png" : "/img/Logo_transparent.png"} alt="logo" height="120" /></Navbar.Brand>
                    {isFundraiser ? null :
                        <React.Fragment>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="mr-auto">
                                    
                                </Nav>
                                
                                <Nav>
                                    <LoggedInOutNavElements isLoggedIn={isLoggedIn} />
                                </Nav>
                            </Navbar.Collapse>
                        </React.Fragment>
                    }
                    </Container>
                </Navbar>
                {container}
            </Router>
        )
    }

    componentDidMount() {
        this.getUser();
    }

    getUser() {
        var self = this;
        firebase.auth.onAuthStateChanged(function(user) {
            if(user) {
                self.setState({ hasDeterminedAuthStatus: true, isLoggedIn: user !== null })
                CookieProvider.set('uid', user.uid);
                CookieProvider.set('email', user.email);
                self.autoCreateUser(user);
            }
        });
    }

    autoCreateUser(user) {
        if (user.displayName) return;
        const displayName = user.email.split("@")[0]
        var userData = {
            displayName: displayName
        }
        db.collection("users").doc(user.uid).set(userData)
        .then(function() {
            return user.updateProfile({
                displayName: displayName,
            })
        });
    }
}

export default App;