class ProgramsRepository {

    foodDonation = {
        title: "FOOD DONATION",
        colourScheme: "veryLightScheme",
        items: [
          {
            textSide: "left",
            images: [{
                src: "/img/fooddonation.jpg",
                alt: "Sacks of food"
            }],
            paragraphs: [
              {
                text: `There are many elemental causes that contribute to hunger, poor diets, and malnutrition in the world and in South America. This includes structural inequality, poverty, sanitation, and hygiene.
                We know and believe that healthy food is a primary human need which everyone should have access to. With this in mind, ‘Projeto Kit Básica’ (Basic Food Kit) was born with the goal of guaranteeing that people with low income have at least the minimum sustenance and nutrition in their daily diets.
                `
              },
              {
                title: "HOW DOES IT WORK?",
                text: `Every month we donate to families in need by providing one kit of food. This kit contains enough food for a family to live off, be well fed and nourished for a whole month. This program helps families to be physically healthy, and therefore, gives them a better chance to win their daily battles.
                `
              }
            ]
          }
        ]
    }

    personalHygieneKit = {
      title: "PERSONAL Hygiene Kit",
      colourScheme: "lightScheme",
      items: [
        {
          textSide: "right",
          images: [{
              src: "/img/personalhygiene.jpg",
              alt: "Showers"
          }],
          paragraphs: [
            {
              text: `Personal hygiene is another essential aspect of human life which is essential for good health and wellbeing. However, we understand that buying soap, shampoo or conditioner may not be the first priority when it comes to those whose monthly earnings are under the minimum income level established by the United Nations.`
            },
            {
              title: `HOW DOES IT WORK?`,
              text: `Through a partnership with Emotion Ikhlas we were able to create ‘Projeto Kit Higienico’ which allows us to donate one personal Hygiene Kit to each individual in need. We are proud of this partnership and project, and we are witnessing a great difference that this program is making in many people's lives.
              `
            }
          ]
        }
      ]
    }
  
    womensEntrepreneurship = {
      title: "WOMEN'S ENTREPRENEURSHIP",
      colourScheme: "veryLightScheme",
      items: [
        {
          textSide: "left",
          images: [{
              src: "/img/womensentrepreneurship.jpg",
              alt: "Women's entrepreneurship"
          }],
          paragraphs: [
            {
              text: `According to a study published by Harvard Business Review it was revealed that when female entrepreneurs seek investment they are often asked questions focused around 'prevention of loss', whilst male entrepreneurs are often asked questions around 'potential gains'. In reality, this represents the reason why men usually get more funding than women.
              `
            },
            {
              title: "HOW WILL WE HELP?",
              text: `Here at ICBAmérica, our programme promotes support for women through various ways. We focus on main profiles below:
              Enterprising women in any industry who already own a micro or small business that needs monetary help to boost sales and help customer retention. It can be within varied forms; buying more equipment to better serve their customers, improving the company's facilities, etc.
              Enterprising women in various industries, such as seamstresses, confectioners and street vendors who need small machinery as well as newer equipment to improve their small business. Here, other partners are of fundamental importance.
              Enterprising women in the cosmetic industry who already own a micro or small business and would like to include a new range of cosmetic products in their offerings. Here our partner Emotion Ikhlas will be key in providing the products.
              Enterprising women in the cosmetic industry who are still working on their business plan, did not open their business yet, and would like to include a new range of cosmetic products in their offering. Here our partner Emotion Ikhlas will be key in providing the products.
              `
            }
          ]
        }
      ]
    }

    sections = [
        this.foodDonation,
        this.personalHygieneKit,
        this.womensEntrepreneurship
    ]
}

export default ProgramsRepository;