import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SectionsLayout from '../SectionsLayout';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Firebase from '../Firebase';
const firebase = new Firebase();

class Volunteering extends React.Component {

  render() {
    return (
      <div className="volunteeringContainer">
        <SectionsLayout sections={this.state.sections} />
        <Form style={{ display: this.state.formSubmitted ? 'none' : 'block' }}>
          <Row>
            <Col sm={6}>
              <Form.Group controlId="name">
                <Form.Label><b>What is your name?</b></Form.Label>
                <Form.Control type="text" placeholder="Enter name" value={this.state.form.name} onChange={this.handleFormChange.bind(this, "name")}/>
              </Form.Group>
              <Form.Group controlId="reasons">
                <Form.Label><b>Why do you want to become an ICBAmérica volunteer?</b></Form.Label>
                <Form.Check type="checkbox" label="I sympathize with the cause" checked={this.state.form.reasons.sympathize} onChange={this.handleCheckboxChange.bind(this, "sympathize")}/>
                <Form.Check type="checkbox" label="To fill my time" checked={this.state.form.reasons.fillTime} onChange={this.handleCheckboxChange.bind(this, "fillTime")}/>
                <Form.Check type="checkbox" label="To improve my professional/school curriculum" checked={this.state.form.reasons.improve} onChange={this.handleCheckboxChange.bind(this, "improve")}/>
              </Form.Group>
              <Form.Group controlId="experience">
                <Form.Label><b>If you have any previous volunteering experience please tell us where and when:</b></Form.Label>
                <Form.Control as="textarea" placeholder="Enter details" value={this.state.form.experience} onChange={this.handleFormChange.bind(this, "experience")}/>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group controlId="email">
                <Form.Label><b>Do you have an email address we can contact you through?</b></Form.Label>
                <Form.Control type="email" placeholder="Enter email" value={this.state.form.email} onChange={this.handleFormChange.bind(this, "email")}/>
              </Form.Group>
              <Form.Group controlId="city">
                <Form.Label><b>What is the name of the city you currently live in?</b></Form.Label>
                <Form.Control type="text" placeholder="Enter city" value={this.state.form.city} onChange={this.handleFormChange.bind(this, "city")}/>
              </Form.Group>
              <Form.Group controlId="request">
                <Form.Label><b>Please let us know if if there is any particular activity you would be especially interested in:</b></Form.Label>
                <Form.Control as="textarea" placeholder="Enter details" value={this.state.form.request} onChange={this.handleFormChange.bind(this, "request")}/>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={{span: 4, offset: 4}}>
              <Button className="mainButton" size="lg" block onClick={this.submitVolunteeringForm}>
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
        <Row style={{ display: this.state.formSubmitted ? 'block' : 'none' }}>
          <Col>
            <h4>THANK YOU</h4>
            <p>Your form was sent successfully. We will get in touch as soon as we can.</p>
          </Col>
        </Row>
      </div>
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      sections: [
        this.volunteering
      ],
      form: {
        name: "",
        reasons: {
          sympathize: false,
          fillTime: false,
          improve: false,
        },
        experience: "",
        email: "",
        city: "",
        request: ""
      },
      formSubmitted: false
    }
    this.submitVolunteeringForm = this.submitVolunteeringForm.bind(this);
  }

  volunteering = {
    title: "DO YOU WANT TO BE A VOLUNTEER?",
    colourScheme: "veryLightScheme",
    items: [
      {
        textSide: "left",
        images: [{
            src: "/img/volunteering.jpg",
            alt: "Woman holding baby"
        }],
        paragraphs: [
          {
            text: `We have many programs and projects for which we need volunteers.
            After you register, you will be invited for an interview where you will learn more about volunteering with us, the volunteer legislation and the terms and conditions. This interview will be made via WhatsApp, Skype, or in person, at a predefined date.
            To fill out your volunteer form is simple, just answer the following questions below and press the ‘Submit’ button once you are done with your answers.            
            `
          }
        ]
      }
    ]
  }

  handleFormChange(path, event) {
    var newForm = this.state.form;
    newForm[path] = event.target.value;
    this.setState({form: newForm});
  }

  handleCheckboxChange(reason, event) {
    var newForm = this.state.form;
    newForm.reasons[reason] = event.target.checked;
    this.setState({form: newForm});
  }

  submitVolunteeringForm() {
    var form = this.state.form;
    if(!this.formValid(form)) return alert("Please fill in all fields");
    var self = this;
    const submitVolunteeringForm = firebase.functions.httpsCallable('submitVolunteeringForm');
    submitVolunteeringForm({form: form})
    .then(function() {
      self.setState({formSubmitted: true});
    })
    .catch(function(error) {
      console.log(error);
      alert(error.message);
    });
  }

  formValid(form) {
    return form.name.length > 0 && form.experience.length > 0 && form.email.length > 0 && form.city.length > 0
  }
}

export default Volunteering;